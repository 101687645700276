section {
    flex: 1 0 auto;
    position: relative;
    width: 100%;
    -webkit-transition: all .5s ease-in;
    -o-transition: all .5s ease-in;
    transition: all .5s ease-in;
}

.who_am_I {
    font-family: Cinzel;
}

.has-first-color {
    color: var(--primary-color);
}

.btn-portfolio {
    background: var(--primary-color);
    border-radius: 0;
}

.btn-portfolio a {
    color: #000;
    text-decoration: none;
}

.btn-about a {
    color: var(--text-color);
    text-decoration: none;
}

.intro_sec {
    height: calc(100vh - 60px);
    min-height: 700px;
    height: 100vh;
    margin-top: -60px;
}

.intro_sec .text,
.intro_sec {
    width: 100%;
}

@media (max-width:991.98px) {
    .intro_sec .text,
    .intro_sec {
        display: block;
        height: auto!important;
        width: 100%;
    }
    .headshot_image {
        flex: 1 1 auto;
        max-height: 10vh;
        background-size: 75%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .cleveland_image {
        flex: 1 1 auto;
        background-size: 75%;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.intro_sec .intro {
    max-width: 450px;
    margin: 0 auto;
}


.intro_sec .intro .feature .wrap-icon {
    background: 0 0!important;
    width: auto;
    height: auto;
    margin-bottom: 0;
}

.intro_sec .intro .feature .wrap-icon svg {
    color: #5cccc9;
}

.intro_sec .text h1 {
    font-size: 30px;
    margin-bottom: 50px;
    font-weight: 700;
}

.intro_sec .text h3 {
    font-size: 16px;
    font-weight: 700;
}

.intro_sec {
    background-size: cover;
    background-position: center;
    min-height: 50vh;
    position: relative;
}

.cleveland_image {
    background-size: 45%;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 50vh;
    position: relative;
}

.headshot_image {
    background-size: 45%;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 50vh;
    position: relative;
}

.ac_btn {
    padding: 4px 19px;
    color: var(--secondary-color);
    position: relative;
    border: var(--secondary-color) 2px solid;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    cursor: pointer;
    border-radius: 0;
    margin-right: 20px;
}

.ac_btn a {
    text-decoration: none;
}

.ac_btn:hover {
    box-shadow: 8px 8px 0px var(--text-color), -8px -8px 0px var(--text-color);
}

.ac_btn:hover .one {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .two {
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .three {
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .four {
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn .ring {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    transform: translate3d(0px, 90px, 0px);
}

.ac_btn .one {
    background-color: #000;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
    z-index: -4;
}

.ac_btn .two {
    background-color: var(--primary-color);
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
}

.ac_btn .three {
    background-color: var(--secondary-color);
    z-index: -2;
    transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
}

#button_p {
    background: var(--secondary-color);
    color: var(--primary-color);
}

#button_h:hover {
    color: var(--primary-color)
}

.intro_sec .block {
    bottom: 50vh;
    max-width: 220px;
    padding: 30px;
    position: absolute;
    width: 250px;
    height: 220px;
    border-radius: 100%;
    background-color: #2f514e;
    z-index: 9999999999;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.photo_section {
    padding-top: 10vh;
    width: 50vw;
    max-height: 100vh;
}

.space_for_mobile {
    display: none;
}
@media (max-width:515px) {
    .space_for_mobile {
        display: block;
        height: 50vh;
        flex: 2 4 auto;
    }
    .intro_sec .headshot_image .block .cleveland_image {
        top: unset;
        bottom: 0;
        width: 100%;
        left: 50%;
        transform: translate(-50%, 50%);
    }
    .photo_section {
        width: 100vw;
        padding-top: 5vh;
        max-height: 100vh;
    }
    .intro {
        position: absolute;
        top: 55vh;
    }
    .home {
        max-height: 100vh;
    }
    .cleveland_image {
        background-size: 75%;
    }
    .headshot_image {
        background-size: 75%;
    }
}
@media (max-width:415px) {
    .space_for_mobile {
        display: block;
        height: 55vh;
        flex: 2 4 auto;
    }
    .intro_sec .block {
        top: unset;
        bottom: 0;
        width: 100%;
        left: 50%;
        transform: translate(-50%, 50%);
    }
    .photo_section {
        width: 100vw;
        padding-top: 5vh;
        max-height: 100vh;
    }
    .intro {
        position: absolute;
        top: 55vh;
    }
    .home {
        max-height: 100vh;
    }
    .cleveland_image {
        background-size: 75%;
    }
    .headshot_image {
        background-size: 75%;
    }
}
@media (max-width:991.98px) and (min-width: 515px) {
    .intro_sec .block {
        top: unset;
        bottom: 0;
        width: 100%;
        left: 50%;
        transform: translate(-50%, 50%);
    }
    .photo_section {
        width: 100vw;
        padding-top: 5vh;
        max-height: 100vh;
    }
    .space_for_mobile {
        display: block;
        height: 55vh;
        flex: 2 4 auto;
    }
    .intro {
        position: absolute;
        top: 55vh;
    }
    .home {
        max-height: 100vh;
    }
    .cleveland_image {
        background-size: 75%;
    }
    .headshot_image {
        background-size: 75%;
    }
}

.intro_sec .block .wrap-icon {
    font-size: 40px;
    color: var(--text-color);
    margin-bottom: 10px;
}

.intro_sec .block h3 {
    font-size: 14px;
    color: var(--text-color);
    letter-spacing: .1rem;
    font-weight: 700;
    margin-bottom: 15px;
}

.intro_sec .block p {
    font-size: 14px;
    line-height: 1.3;
    color: var(--text-color)
}

.intro_sec .headshot_image {
    filter: saturate(0.9);
}
